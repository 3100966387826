//Libraries
import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong'
import { starO } from 'react-icons-kit/fa/starO'
import ReaderIcon from '../../../assets/image/reader.svg'

import {
  Container,
  Title,
  SmartWayText,
  Affiliate,
  Logo,
  Footer,
  SmartWayCardsContainer,
  SmartWayTitleContainer,
  SmartWayTitle,
  SmartWayCard,
  LogoContainer
} from './styles'

const SmartWay = () => {
  const {
    strapiCloverly: { data, smartway }
  } = useStaticQuery(graphql`
    {
      strapiCloverly {
        smartway {
          url
        }
        data {
          smartway {
            title
            footer {
              text
              title
            }
            cards {
              title
              text
            }
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Title>{data.smartway.title.toUpperCase()}</Title>
      <SmartWayCardsContainer>
        {data.smartway?.cards?.map((el, index) => (
          <SmartWayCard key={index}>
            <SmartWayTitleContainer>
              {index === 0 ? (
                <Icon
                  style={{ marginRight: 15 }}
                  size={22}
                  icon={iosSearchStrong}
                />
              ) : null}
              {index === 1 ? (
                <Icon style={{ marginRight: 15 }} size={22} icon={starO} />
              ) : null}
              {index === 2 ? (
                <img src={ReaderIcon} style={{ marginRight: 15 }} size={22} />
              ) : null}
              <SmartWayTitle>{el?.title}</SmartWayTitle>
            </SmartWayTitleContainer>
            <SmartWayText>{el?.text}</SmartWayText>
          </SmartWayCard>
        ))}
      </SmartWayCardsContainer>
      <Footer>
        <Affiliate>
          <h2>{data.smartway.footer.title}</h2>
          <p>{data.smartway.footer.text}</p>
        </Affiliate>
        <LogoContainer>
          <Logo src={smartway[0]?.url} />
        </LogoContainer>
      </Footer>
    </Container>
  )
}

export default SmartWay
