import { useStaticQuery, graphql } from 'gatsby'
import React, { useState } from 'react'
import { Container, ContentLogo, TextSection, Logo } from './styles'
import LetsConnect from '../LetsConnect'

import { Icon } from 'react-icons-kit'
import { ic_control_point } from 'react-icons-kit/md/ic_control_point'

const Header = () => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const {
    strapiCloverly: { data, logo }
  } = useStaticQuery(graphql`
    {
      strapiCloverly {
        data {
          header {
            text {
              top
              middle
              bottom
            }
            title
            upperTitle
          }
        }
        logo {
          publicURL
        }
      }
    }
  `)

  return (
    <Container>
      <ContentLogo>
        <Logo src={logo?.publicURL} />
      </ContentLogo>
      <TextSection>
        <h2>{data.header?.upperTitle}</h2>
        <h1>{data.header?.title}</h1>
        <div className="supply-container">
          <div className="supply-item">
            <Icon
              size={22}
              style={{ marginRight: 15 }}
              icon={ic_control_point}
            />
            <h5>{data.header?.text?.top}</h5>
          </div>
          <div className="supply-item">
            <Icon
              size={22}
              style={{ marginRight: 15 }}
              icon={ic_control_point}
            />
            <h5>{data.header?.text?.middle}</h5>
          </div>
          <div className="supply-item">
            <Icon
              size={22}
              style={{ marginRight: 15 }}
              icon={ic_control_point}
            />
            <h5>{data.header?.text?.bottom}</h5>
          </div>
        </div>
        <button type="button" onClick={handleOpen}>
          Let's Connect
        </button>
      </TextSection>
      <LetsConnect isOpened={open} handleClose={handleClose} />
    </Container>
  )
}

export default Header
