import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  min-height: 500px;
  padding: 15rem 10rem 20rem;

  @media (max-width: 1024px) {
    padding: 15rem 10rem 20rem;
  }
  @media (max-width: 768px) {
    padding: 12rem 0 20rem;
  }

  button {
    width: 330px;
    height: 70px;
    display: block;
    font-weight: 500;
    font-size: 28px;
    background: linear-gradient(90deg, #32a0de 25.9%, #3c7cf3 73.77%);
    border-radius: 8px;
    color: white;
    border: none;
    cursor: pointer;

    @media (max-width: 1024px) {
      font-size: 24px;
      width: 266px;
      height: 60px;
    }
    @media (max-width: 768px) {
      font-size: 20px;
      width: 240px;
      height: 55px;
    }
    @media (max-width: 375px) {
      font-size: 16px;
      width: 169px;
      height: 43px;
    }
  }
`
export const ContentLogo = styled.div`
  width: 100%;
  position: relative;
`

export const Logo = styled.img`
  max-width: 220px;
  width: 220px;
  height: auto;
  position: absolute;
  right: 24rem;
  top: -10rem;

  @media (max-width: 1920px) {
    right: 24rem;
    top: -10rem;
  }
  @media (max-width: 1440px) {
    right: 3rem;
    top: -10rem;
  }
  @media (max-width: 1024px) {
    right: 0;
    top: -10rem;
  }
  @media (max-width: 768px) {
    right: 0;
    top: -10rem;
  }
`

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0em 22rem 5rem;
  color: white;
  width: 100%;

  @media (max-width: 1920px) {
    padding: 0em 22rem 5rem;
  }
  @media (max-width: 1440px) {
    padding: 0em 4rem 5rem;
  }
  @media (max-width: 1024px) {
    padding: 0em 0 5rem;
  }
  @media (max-width: 768px) {
    padding: 0em 0 5rem;
  }

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;

    @media (max-width: 1440px) {
      font-size: 32px;
    }
    @media (max-width: 1024px) {
      font-size: 20px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
    @media (max-width: 375px) {
      font-size: 12px;
    }
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    text-transform: uppercase;

    @media (max-width: 1440px) {
      font-size: 56px;
    }
    @media (max-width: 1024px) {
      font-size: 44px;
    }
    @media (max-width: 768px) {
      font-size: 26px;
    }
    @media (max-width: 375px) {
      font-size: 20px;
    }
  }

  .supply-container {
    padding: 3rem 5rem 5rem;

    @media (max-width: 768px) {
      padding: 3rem 0 2rem;
    }
  }

  .supply-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
  }

  span {
    padding: 10px 15px;
  }

  h5 {
    font-weight: 500;
    font-size: 22px;
    margin: 0;

    @media (max-width: 1024px) {
      font-size: 16px;
    }
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`
