import styled from 'styled-components'
import Carousel from 'react-multi-carousel'

export const SliderContainer = styled.div`
  width: 60%;
  position: relative;
  margin: 5rem auto;

  @media (max-width: 1440px) {
    width: 75%;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }

  .react-multi-carousel-dot-list {
    bottom: -45px;
  }

  .react-multi-carousel-dot button {
    margin: 0 8px;
  }
`

export const SliderStyles = styled(Carousel)`
  .slider-item {
    display: flex;
    flex-direction: row;
  }

  .slider-item section:first {
    display: flex;
    align-items: flex-start;
  }

  .slider-item .slider-image {
    width: auto;
    height: 370px;
    text-align: center;
    display: flex;
    align-items: flex-center;
    margin: 0 auto;
    background-size: contain;

    @media (max-width: 768px) {
      height: 170px;
    }
  }
`
export const AtEdge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  color: white;
  padding: 2rem 40rem 5rem;
  text-align: center;

  @media (max-width: 1440px) {
    padding: 2rem 20rem 5rem;
  }
  @media (max-width: 1024px) {
    padding: 2rem 4rem 5rem;
  }
  @media (max-width: 768px) {
    padding: 2rem 0 5rem;
  }
  @media (max-width: 375px) {
    padding: 2rem 0 5rem;
  }

  h1 {
    font-weight: 800;
    font-size: 32px;
    padding-bottom: 3rem;
    text-transform: uppercase;

    @media (max-width: 1440px) {
      font-size: 28px;
    }
    @media (max-width: 768px) {
      font-size: 24px;
    }
    @media (max-width: 375px) {
      font-size: 20px;
    }
  }

  p {
    font-weight: 500;
    font-size: 24px;
    padding-bottom: 3rem;
    margin: 0;

    @media (max-width: 1440px) {
      font-size: 20px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
    }
  }
`
