import React from 'react'
import Modal from '@material-ui/core/Modal'
import HubspotForm from 'react-hubspot-form'
import { FormContainer, FormWrapper, FormHeader } from './styles'

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`
  }
}

const LetsConnect = ({ isOpened, handleClose }) => {
  const GATSBY_HUBSPOT_PORTAL_ID = 7940905
  const GATSBY_HUBSPOT_CLOVERLY_FORM_ID = 'a0201f53-2d87-4b2c-af4d-6e1b47dfce08'
  const [modalStyle] = React.useState(getModalStyle)

  const body = (
    <FormContainer className="lets-connect-cloverly-form" style={modalStyle}>
      <FormHeader>Let's Connect</FormHeader>
      <FormWrapper>
        <p className="contact">Contact Information</p>
        <HubspotForm
          portalId={GATSBY_HUBSPOT_PORTAL_ID}
          formId={GATSBY_HUBSPOT_CLOVERLY_FORM_ID}
          loading={<div>Loading...</div>}
        />
      </FormWrapper>
    </FormContainer>
  )

  return (
    <Modal
      open={isOpened}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  )
}

export default LetsConnect
