import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { ic_arrow_forward } from 'react-icons-kit/md/ic_arrow_forward'

import {
  Container,
  Link,
  MoreText,
  Title,
  HashText,
  LogoContainer,
  CloverlyLogo,
  Text,
  CloverlyImage,
  CloverlyImageContainer,
  CloverlyLogoContainer,
  TitleContainer,
  InnerContainer
} from './styles'

const RoadsSection = () => {
  const {
    strapiCloverly: { data, roadstoroots, cloverlyLogo }
  } = useStaticQuery(graphql`
    {
      strapiCloverly {
        roadstoroots {
          publicURL
        }
        data {
          roads {
            and
            hash
            linkText
            more
            title
          }
        }
        cloverlyLogo {
          publicURL
        }
      }
    }
  `)

  return (
    <Container>
      <InnerContainer>
        <TitleContainer>
          <Title>{data.roads.title}</Title>
          <HashText>{data.roads.hash}</HashText>
          <LogoContainer>
            <Text>{data.roads.and}</Text>
            <CloverlyLogoContainer>
              <CloverlyLogo src={cloverlyLogo.publicURL} />
            </CloverlyLogoContainer>
          </LogoContainer>

          <div>
            <MoreText>{data.roads.more}</MoreText>
            <Link href="https://blog.edgelogistics.com/">
              {data.roads.linkText} <Icon icon={ic_arrow_forward} />
            </Link>
          </div>
        </TitleContainer>
        <CloverlyImageContainer>
          <CloverlyImage src={roadstoroots.publicURL} />
        </CloverlyImageContainer>
      </InnerContainer>
    </Container>
  )
}

export default RoadsSection
