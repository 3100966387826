//  libraries
import React from 'react'

//  components
import Layout from 'components/layout'
import SEO from 'components/seo'
import Container from '../containers/Cloverly/Container'
import Header from '../containers/Cloverly/Header'
import Slider from '../containers/Cloverly/Slider'
import SmartWay from '../containers/Cloverly/SmartWay'
import RoadsSection from '../containers/Cloverly/Roads'

const Cloverly = () => {
  return (
    <Layout withoutScrollSnap>
      <SEO title="Sustainability" />
      <Container>
        <Header />
        <Slider />
      </Container>
      <SmartWay />
      <RoadsSection />
    </Layout>
  )
}

export default Cloverly
