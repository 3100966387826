import styled from 'styled-components'

export const Module = styled.div`
  background-color: white;
  padding: 1rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: initial;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 160px;
  background-position: 50% 50%;
`
