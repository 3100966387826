//Libraries
import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import { Module } from './styles'

const Container = ({ children }) => {
  const {
    strapiCloverly: { mountains }
  } = useStaticQuery(graphql`
    {
      strapiCloverly {
        mountains {
          publicURL
        }
      }
    }
  `)

  return (
    <Module
      style={{
        // backgroundImage: `url('${mountains.publicURL}')`,
        backgroundImage: `linear-gradient(to top,  rgba(255,255,255,1) 0%, rgba(0,0,0,0) 160px), url('${mountains.publicURL}')`
        // background: linear-gradient(to top,  rgba(255,255,255,1) 0%, rgba(0,0,0,0) 160px),
      }}
    >
      {children}
    </Module>
  )
}

export default Container
