import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  height: auto;
  color: white;
  justify-conent: center;
  align-items: center;
  transform: translateY(-160px);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.87) 80px
  );

  padding: 60px 20px;
  padding-top: 260px;
`

export const Title = styled.h1`
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  max-width: 938px;
  text-align: center;
  margin-bottom: 68px;
  @media (max-width: 1440px) {
    font-size: 28px;
  }
  @media (max-width: 1024px) {
    font-size: 24px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
`

export const SmartWayCardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
export const SmartWayCard = styled.div`
  margin-right: 15px;
  margin-left: 15px;
  max-width: 360px;
  padding: 20px;
  flex: 1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(102.38deg, #3c7cf3 0.94%, #41ba8c 99.11%);
  border-radius: 8px;
  flex-direction: column;
  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 30px;
  }
`
export const SmartWayTitle = styled.h2`
  color: white;
  font-size: 24px;
  font-weight: 500;
`
export const SmartWayTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const SmartWayText = styled.div`
  @media (min-width: 1440px) {
    font-size: 22px;
  }
  @media (min-width: 1024px) {
    font-size: 20px;
  }
  @media (min-width: 768px) {
    font-size: 16px;
  }
`

export const Logo = styled.img`
  width: 100%;
  max-width: 950px;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 50px 23px;
`
export const LogoContainer = styled.div`
  max-width: 470px;
  flex: 1;
`

export const Affiliate = styled.div`
  max-width: 650px;
  margin-right: 15px;
  margin-left: 15px;
  flex: 1;
  h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 48px;
    text-transform: uppercase;
    color: #3c7cf3;
    @media (max-width: 1440px) {
      font-size: 28px;
    }
    @media (max-width: 1024px) {
      font-size: 24px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  p {
    font-size: 24px;
    line-height: 28px;
    @media (max-width: 1440px) {
      font-size: 24px;
    }
    @media (max-width: 1024px) {
      font-size: 20px;
    }
    @media (max-width: 768px) {
      font-size: 12px;
      text-align: center;
    }
  }
`
export const Footer = styled.div`
  margin-top: 160px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 30px;
  }
`
