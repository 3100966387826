import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import { SliderContainer, SliderStyles, AtEdge } from './styles'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const Slider = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1025 },
      items: 1,
      paritialVisibilityGutter: 80
    },
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 1,
      paritialVisibilityGutter: 50
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      paritialVisibilityGutter: 50
    }
  }

  const {
    strapiCloverly: { cloverlyCarrousel, data }
  } = useStaticQuery(graphql`
    {
      strapiCloverly {
        cloverlyCarrousel {
          url
        }
        data {
          edge {
            title
            text {
              bottom
              middle
              top
            }
          }
        }
      }
    }
  `)

  return (
    <div>
      <SliderContainer>
        <SliderStyles
          arrows={true}
          itemClass="slider-item"
          renderDotsOutside
          responsive={responsive}
          showDots
          draggable
          swipeable
          infinite
          slidesToSlide={1}
          autoPlay={true}
          autoPlaySpeed={5000}
          transitionDuration={600}
        >
          {cloverlyCarrousel.map((item) => (
            <img className="slider-image" key={item.url} src={item.url} />
          ))}
        </SliderStyles>
      </SliderContainer>
      <AtEdge>
        <h1>{data.edge?.title}</h1>
        <p>{data.edge?.text?.top}</p>
        <p>{data.edge?.text?.middle}</p>
        <p>{data.edge?.text?.bottom}</p>
      </AtEdge>
    </div>
  )
}

export default Slider
