import styled from 'styled-components'

export const FormContainer = styled.div`
  position: absolute;
  width: 368px;
`

export const FormWrapper = styled.div`
  background: white;
  border-radius: 20px;
  padding: 20px;
  font-family: 'Poppins';
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .contact {
    color: #2b3e50;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    margin: 0;
    padding-bottom: 20px;
  }

  label {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    padding-bottom: 5px;
    color: #2b3e50;
  }

  input,
  textarea {
    min-height: 30px;
    background: #f3f7f9;
    border: 1px solid #cbd6e2;
    border-radius: 8px;
    padding: 4px 8px;
  }

  .input input.hs-input {
    width: 100% !important;
  }

  textarea {
    min-height: 80px;
    width: 100% !important;
    margin-bottom: 12px;
  }

  .field {
    padding-bottom: 12px;
  }

  .actions input {
    font-family: 'Poppins';
    background: linear-gradient(90deg, #32a0de 25.9%, #3c7cf3 73.77%);
    border-radius: 8px;
    width: 100%;
    height: 34px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: white;
    border: none;
    cursor: pointer;
  }
`

export const FormHeader = styled.h1`
  width: 368px;
  height: 57px;
  display: flex;
  font-weight: 500;
  font-size: 28px;
  background: linear-gradient(90deg, #32a0de 25.9%, #3c7cf3 73.77%);
  border-radius: 8px;
  color: white;
  border: none;
  margin-bottom: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 33px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`
