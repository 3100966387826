import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  background: white;
  height: auto;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;
  justify-content: center;
`
export const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  @media (max-width: 1440px) {
    font-size: 24px;
  }
  @media (max-width: 1024px) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`

export const TitleContainer = styled.div`
  margin-bottom: 50px;
  flex: 1;
`
export const HashText = styled.p`
  color: #41ba8c;
  font-size: 68px;
  font-style: italic;
  margin-bottom: 40px;
  @media (max-width: 1920px) {
    font-size: 68px;
  }
  @media (max-width: 1440px) {
    font-size: 64px;
  }
  @media (max-width: 1024px) {
    font-size: 48px;
  }
  @media (max-width: 768px) {
    font-size: 36px;
  }
`

export const Link = styled.a`
  color: #32a0de;
  background_color: transparent;
  font-size: 28px;
  text-decoration: none;
  @media (max-width: 1024px) {
    font-size: 24px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  }
  cursor: pointer;
`
export const MoreText = styled.p`
  font-size: 20px;
  font-weight: 300;
  @media (max-width: 1024px) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`
export const Text = styled.p`
  font-size: 24px;
  font-weight: 500;
  width: fit-content;
  margin-top: 4px;
  margin-bottom: 0px;
  margin-right: 10px;
`
export const LogoContainer = styled.div`
  flex-direction: row;
  margin-bottom: 80px;
  @media (max-width: 1920px) {
    margin-bottom: 80px;
  }
  @media (max-width: 1440px) {
    margin-bottom: 60px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 50px;
  }
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`
export const CloverlyLogoContainer = styled.div`
  max-width: 141px;
`
export const CloverlyLogo = styled.img`
  width: 141px;
`
export const CloverlyImage = styled.img`
  width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  flex: 1;
`
export const CloverlyImageContainer = styled.div`
  max-width: 700px;
`
export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: max-content;
  padding: 0px 30px;
  align-items: center;
  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
  }
`
